<template>
  <v-flex class="chart_properties" wrap>

    <div class="chart_properties_header">
      <span>Columns</span>
      <small class="type_ref01">Properties</small>
    </div>

    <div class="chart_properties_contents">      
      <j-alert
        v-model="msgOpen"
        :title="msgInfo.title"
        :type="msgInfo.type"
        :message="msgInfo.message"
        :button="msgInfo.button"
        :button-text="msgInfo.buttonText"
        @yes="yes()"
        @cancel="msgOpen = false"
      />

      <div class="header">
        <span 
          data-grid-quickbtn="btn" 
          class="label jcon_temp_plus type_header btn_after_55" 
          style="font-family: roboto !important; color: #83d2f5;" 
          @click="appendAttr('Columns')"
        >Columns</span>
        <a class="close" v-on:click="collapsed['columns'] = !collapsed['columns']"></a>
      </div>

      <v-flex class="control" v-show="collapsed['columns']">

        <div v-for="(attr, i) in localColumns.RealData.Columns" class="content_list"  :key="attr.index">

          <div class="header" style="margin-bottom: 2px;">
            <span data-grid-quickbtn="btn" class="label jcon_temp_minus type_header_before" @click="clickRemoveBtn(i, 'Columns')">Col. {{ i + 1 }}</span>
          </div>

          <div class="type_inner">

            <div class="type_common_input">
              <label>Title</label>
              <input v-model="attr.title" type="text" class="type70" @input="updateAttr" />
            </div>

            <div class="type_common_input">
              <label>Ref Col</label>
              <input v-model="attr.ref" type="text" class="type70" @input="updateAttr" />
            </div>

            <div class="type_common_input">
              <label>Group</label>
              <input v-model="attr.group" type="text" class="type70" @input="updateAttr" />
            </div>

            <div class="type_common_input">
              <label>Width</label>
              <input v-model.number="attr.width" type="number" @input="updateAttr" />
            </div>

            <div class="type_common_select">
              <label>Type</label>
              <select v-model="attr.type" dir="rtl" style="width: 4rem;" @change="updateAttr">
                <option v-for="option in typeOptions" :key="option.index" :value="option.value">{{option.text}}</option>
              </select>
            </div>

            <div class="type_common_input">
              <label>Format</label>
              <input v-model="attr.format" type="text" @input="updateAttr" />
            </div>

            <div class="type_common_input">
              <label>Text Color</label>
              <input v-model="attr.tColor" type="text" class="type70" @input="updateAttr" />
            </div>

            <div class="type_common_select">
              <label>Align</label>
              <select v-model="attr.align" dir="rtl" style="width: 4rem;" @change="updateAttr">
                <option v-for="option in alignOptions" :key="option.index" :value="option.value">{{option.text}}</option>
              </select>
            </div>

            <div class="type_common_input">
              <label>Overlap</label>
              <div class="row">
                <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
                  <input v-model="attr.overlap" type="radio" :name="`overlap_${i}`" :value="option.value" @input="(e) => { updateAttr(e.target.value, i, 'overlap', 'Columns') }"/>
                  {{option.text}}
                </label>
              </div>
            </div>

            <div class="type_common_input">
              <label>Zero</label>
              <div class="row">
                <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
                  <input v-model="attr.zero" type="radio" :name="`zero_${i}`" :value="option.value" @input="(e) => { updateAttr(e.target.value, i, 'zero', 'Columns') }"/>
                  {{option.text}}
                </label>
              </div>
            </div>

            <div class="type_common_input">
              <label>Last Value</label>
              <div class="row">
                <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
                  <input v-model="attr.lastOne" type="radio" :name="`lastOne_${i}`" :value="option.value" @input="(e) => { updateAttr(e.target.value, i, 'lastOne', 'Columns') }"/>
                  {{option.text}}
                </label>
              </div>
            </div>

            <div class="type_common_input">
              <label>Filter</label>
              <input v-model="attr.filter" type="text" class="type120" @input="updateAttr" />
            </div>

          </div>

        </div>

      </v-flex>
    </div>

    <div class="chart_properties_contents">
      <div class="header">
        <span 
          data-grid-quickbtn="btn" 
          class="label jcon_temp_plus type_header btn_after_55" 
          style="font-family: roboto !important; color: #83d2f5;" 
          @click="appendAttr('Filters')"
        >Filters</span>
        <a class="close" v-on:click="collapsed['filters'] = !collapsed['filters']"></a>
      </div>

      <v-flex class="control" v-show="collapsed['filters']">

        <div v-for="(attr, i) in localColumns.RealData.Filters" class="content_list"  :key="attr.index">

          <div class="header" style="margin-bottom: 2px;">
            <span data-grid-quickbtn="btn" class="label jcon_temp_minus type_header_before" @click="clickRemoveBtn(i, 'Filters')">Key {{ i + 1 }}</span>
          </div>

          <div class="type_inner">

            <div class="type_common_input">
              <label class="type_gray second_depth">Ref Col</label>
              <input v-model="attr.ref" type="text" class="type70" @input="updateAttr" />
            </div>

            <div class="type_common_input">
              <label class="type_gray label second_depth">Json Map</label>
              <input v-model="attr.jMap" type="text" class="type70" @input="updateAttr" />
            </div>

          </div>

        </div>

      </v-flex>
    </div>

  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import __F from '@/primitives/_function_'
import { mapState, mapMutations, mapGetters } from "vuex"
import { ChartLibraryService } from "@/services"
import { QueryValidationMixin } from "@/mixins/sqlValidator"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-col',
  mixins: [
    QueryValidationMixin,
    StaticOptions
  ],
  data: () => ({
    chartLibraryService: null,
    
    visible: true,

    collapsed: {
      columns: true,
      filters: true,
    },
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Delete", "No", "Cancel"]
    },
    localColumns: {
      Default: {
        Columns: [
          {title: 'Area', ref: 'AREA', group: '', width: 80, type: 'TEXT', format: '', tColor: '#757575', align: 'start', overlap: 'N', zero: 'Y', lastOne: 'N', filter: ''},
          {title: 'Disc.', ref: 'DISC', group: '', width: 80, type: 'TEXT', format: '', tColor: '#757575', align: 'start', overlap: 'Y', zero: 'Y', lastOne: 'N', filter: ''},
          {title: 'Total', ref: 'TOTAL', group: '', width: 40, type: 'LINK', format: '$', tColor: '#757575', align: 'end', overlap: 'Y', zero: 'Y', lastOne: 'Y', filter: ''},
          {title: 'Start', ref: 'PS_CUM', group: 'Plan', width: 40, type: 'LINK', format: '$', tColor: '#757575', align: 'end', overlap: 'Y', zero: 'Y', lastOne: 'Y', filter: ''},
          {title: 'Issued', ref: 'PF_CUM', group: 'Plan', width: 40, type: 'LINK', format: '$', tColor: '#757575', align: 'end', overlap: 'Y', zero: 'Y', lastOne: 'Y', filter: ''},
          {title: 'Start', ref: 'AS_CUM', group: 'Actual', width: 40, type: 'LINK', format: '$', tColor: '#757575', align: 'end', overlap: 'Y', zero: 'Y', lastOne: 'Y', filter: ''},
          {title: 'Issued', ref: 'AF_CUM', group: 'Actual', width: 40, type: 'LINK', format: '$', tColor: '#757575', align: 'end', overlap: 'Y', zero: 'Y', lastOne: 'Y', filter: ''},
          {title: 'Start', ref: 'OS', group: 'Overdue', width: 40, type: 'LINK', format: '$', tColor: '#f370ac', align: 'end', overlap: 'Y', zero: 'N', lastOne: 'Y', filter: ''},
          {title: 'Issued', ref: 'OF', group: 'Overdue', width: 40, type: 'LINK', format: '$', tColor: '#f370ac', align: 'end', overlap: 'Y', zero: 'N', lastOne: 'Y', filter: ''},
          {title: 'Plan', ref: 'PLAN', group: 'Progress', width: 40, type: 'LINK', format: '%', tColor: '#757575', align: 'end', overlap: 'Y', zero: 'Y', lastOne: 'Y', filter: ''},
          {title: 'Actual', ref: 'ACT', group: 'Progress', width: 80, type: 'BAR', format: '%', tColor: '#757575', align: 'end', overlap: 'Y', zero: 'Y', lastOne: 'Y', filter: ''},
          {title: 'Delta', ref: 'DEV', group: 'Progress', width: 40, type: 'DELTA', format: '', tColor: '#757575', align: 'end', overlap: 'Y', zero: 'Y', lastOne: 'Y', filter: ''},
        ],
        Filters: [
          {ref: 'AREA', jMap: 'KEY1'},
        ],
      },
      RealData:{
        Columns: [],
        Filters: [],
      },
    },
    exp: {},
    validated: {},

    yes: null,
    msgOpen: false,

    typeOptions: [
      {text: 'Text', value: 'TEXT'},
      {text: 'Link', value: 'LINK'},
      {text: 'Delta', value: 'DELTA'},
      {text: 'Bar', value: 'BAR'},
    ],
    alignOptions: [
      {text: 'start', value: 'start'},
      {text: 'middle', value: 'middle'},
      {text: 'end', value: 'end'},
    ]
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),

    __F_() { return __F },
  },
  watch: {
    'chartItem.Chtcol': {
      handler(val) {
        if (!val) return
        // console.log('Chtcol', val)
        this.localColumns.RealData = JSON.parse(JSON.stringify(val))
        
        let exp_ = {}
        let validated_ = {}
        this.localColumns.RealData.Columns.forEach((_, i) => {
          exp_[`F_${i}`] = false
          validated_[`FILTER_${i}`] = { valid: true, message: ''}
        })

        this.exp = exp_
        this.validated = validated_
      },
      deep: true
    }
  },
  created() {
    this.chartLibraryService = new ChartLibraryService()
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),

    appendAttr(name) {
      // console.log('appendAttr', name)
      // console.log('appendAttr', this.localColumns,this.localColumns.Default[name])
      this.localColumns.RealData[name].push(this.localColumns.Default[name][0])
      this.updateAttr()
    },
    clickRemoveBtn(i, j, el) {
      // console.log('clickRemoveBtn', i, j, el)
      this.yes = () => {
        this.msgOpen = false
        this.removeAttr(i, j, el)
        this.yes = () => {}
      }
      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Remove Style"
      this.msgInfo.message = "Do you want to delete this style?"
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true
    },
    closeScripter(name) {
      this.exp[name]=false
      this.updateAttr()
    },
    
    getQueryParts(i) {
      return this.localColumns.RealData.Columns[i].filter ? this.localColumns.RealData.Columns[i].filter.substring(0, 50) + ' ...' : ''
    },
    openTextarea(i, opened) {
      this.exp[`F_${i}`] = opened
    },
    removeAttr(index, name) {
      // console.log('removeAttr', index, name)
      // console.log('removeAttr', this.localColumns,this.localColumns[name])
      this.localColumns.RealData[name][index] = null
      this.localColumns.RealData[name] = this.localColumns.RealData[name].filter(attr => !!attr)
      this.updateAttr()
    },
    updateAttr(val, i, el, name) {
      if(el) this.localColumns.RealData[name][i][el] = val
      this.setChartItem({ Chtcol: JSON.parse(JSON.stringify(this.localColumns.RealData)) })
    },
    onSave() {
      this.$emit('save')
    },
    onValidate(i) {
      // this.validateSql(this.localColumns.Columns[i].filter).then(res => {
      //   this.validated[`FILTER_${i}`] = res
      // })
    },
  }
}
</script>
