<template>
  <v-flex class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Types</span>
      <small class="type_ref01">Properties</small>
    </div>

    <div class="chart_properties_contents">
      <j-alert
        v-model="msgOpen"
        :title="msgInfo.title"
        :type="msgInfo.type"
        :message="msgInfo.message"
        :button="msgInfo.button"
        :button-text="msgInfo.buttonText"
        @yes="yes()"
        @cancel="msgOpen = false"
      />

      <div class="chart_properties_contents">
        <div class="header">
          Header
          <a class="close" v-on:click="collapsed['header'] = !collapsed['header']"></a>
        </div>
        <v-flex class="control" v-show="collapsed['header']">
          <div class="type_common_input">
            <label>Color</label>
            <input v-model="ChtTypeHeaderFillColor" class="type70" type="text"/>
          </div>
          <div class="type_common_input">
            <label>Opacity</label>
            <input v-model="ChtTypeHeaderFillOpacity" type="number"/>
          </div>
          <div class="type_common_input">
            <label>Margin(Top/Bottom)</label>
            <input v-model="ChtTypeHeaderMargin" type="number"/>
          </div>
          <div class="type_common_input">
            <label>Line</label>
            <div class="row">
              <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
                <input v-model="ChtTypeHeaderLineVisible" type="radio" name="ChtTypeHeaderLineVisible" :value="option.value"/>
                {{option.text}}
              </label>
            </div>
          </div>
          <div class="type_inner">
            <div class="type_common_input">
              <label class="type_gray second_depth">Color</label>
              <input v-model="ChtTypeHeaderLineColor" type="text" class="type70" @input="updateAttr"/>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Opacity / Width</label>
                <input v-model="ChtTypeHeaderLineOpacity" type="number" class="type_mgl0 type_double"/>
                <input v-model="ChtTypeHeaderLineWidth" type="number"/>
            </div>
          </div>
          <div class="type_common_input">
            <label>Devide</label>
            <div class="row">
              <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
                <input v-model="ChtTypeHeaderDevide" type="radio" name="ChtTypeHeaderDevide" :value="option.value"/>
                {{option.text}}
              </label>
            </div>
          </div>
          <div v-for="(attr, i) in localChttype.header.depth" class="content_list"  :key="attr.index">
            <div class="type_common_input">
              <label>Depth {{ i+1 }}</label>
            </div>
            <div class="type_inner">
              <div class="type_common_input">
                <label class="type_gray second_depth">Height</label>
                <input v-model.number="attr.height" type="number" @input="updateAttr" />
              </div>

              <div class="type_common_input">
                <label class="type_gray second_depth">Text Size</label>
                <input v-model.number="attr.tSize" type="number" @input="updateAttr" />
              </div>

              <div class="type_common_input">
                <label class="type_gray second_depth">Text Color</label>
                <input v-model="attr.tColor" type="text" class="type70" @input="updateAttr" />
              </div>
            </div>
          </div>
        </v-flex>
      </div>

      <div class="chart_properties_contents">
        <div class="header">
          <span 
            data-grid-quickbtn="btn" 
            class="label jcon_temp_plus type_header btn_after_55" 
            style="font-family: roboto !important; color: #83d2f5;" 
            @click="appendAttr('group')"
          >Group</span>
          <a class="close" v-on:click="collapsed['group'] = !collapsed['group']"></a>
        </div>
        <v-flex class="control" v-show="collapsed['group']">
          <div v-for="(attr, i) in localChttype.header.group" class="content_list"  :key="attr.index">
            <div class="header" style="margin-bottom: 2px;">
              <span data-grid-quickbtn="btn" class="label jcon_temp_minus type_header_before" @click="clickRemoveBtn(i, 'group')">Group {{ i + 1 }}</span>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Title</label>
              <input v-model="attr.title" type="text" class="type70" @input="updateAttr" />
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Title Color</label>
              <input v-model="attr.tColor" type="text" class="type70" @input="updateAttr" />
            </div>
          </div>
        </v-flex>
      </div>

      <div class="chart_properties_contents">
        <div class="header">
          Cells
          <a class="close" v-on:click="collapsed['cells'] = !collapsed['cells']"></a>
        </div>
        <v-flex class="control" v-show="collapsed['cells']">
          <div class="type_common_input">
            <label>Text Size</label>
            <input v-model="ChtTypeCellsTextSize" type="number"/>
          </div>
          <div class="type_common_input">
            <label>Height</label>
            <input v-model="ChtTypeCellsHeight" type="number"/>
          </div>
          <div class="type_common_input">
            <label>Padding</label>
            <input v-model="ChtTypeCellsPadding" type="number"/>
          </div>
          <div class="type_common_input">
            <label>Total</label>
            <div class="row">
              <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
                <input v-model="ChtTypeCellsTotal" type="radio" name="ChtTypeCellsTotal" :value="option.value"/>
                {{option.text}}
              </label>
            </div>
          </div>

          <div class="type_common_input">
            <label>Line</label>
            <div class="row">
              <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
                <input v-model="ChtTypeCellsLineVisible" type="radio" name="ChtTypeCellsLineVisible" :value="option.value"/>
                {{option.text}}
              </label>
            </div>
          </div>
          <div class="type_inner">
            <div class="type_common_input">
              <label class="type_gray second_depth">Color</label>
              <input v-model="ChtTypeCellsLineColor" type="text" class="type70" @input="updateAttr" />
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Opacity / Width</label>
                <input v-model="ChtTypeCellsLineOpacity" type="number" class="type_mgl0 type_double" @input="updateAttr" />
                <input v-model="ChtTypeCellsLineWidth" type="number" @input="updateAttr" />
            </div>
          </div>

          <div class="type_common_input">
            <label>Bar</label>
          </div>
          <div class="type_inner">
            <div class="type_common_input">
              <label class="type_gray second_depth">Height</label>
              <input v-model="ChtTypeCellsBarHeight" type="number" @input="updateAttr" />
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Color</label>
              <input v-model="ChtTypeCellsBarColor" type="text" class="type70" @input="updateAttr" />
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Opacity</label>
              <input v-model="ChtTypeCellsBarOpacity" type="number" @input="updateAttr" />
            </div>
          </div>

        </v-flex>
      </div> 
    </div> 
    
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-type',
  mixins: [
    StaticOptions
  ],
  components: {
  },
  data: () => ({
    collapsed: {
      header: true,
      group: true,
      cells: true,
    },
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Delete", "No", "Cancel"]
    },
    localChttype: {
      header:{
        line: {},
        depth: [],
        group: [],
      },
      cells:{
        line: {},
        bar: {},
      },
    },
    default: {
      group: [
        {
          title: 'Overdue',
          tColor: '#f370ac',
        },
      ]
    },

    yes: null,
    msgOpen: false,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),
    
    ChtTypeHeaderFillColor: {
      get() { return ( this.chartItem.Chttype && this.chartItem.Chttype.header.fColor ) },
      set(val) { 
        this.localChttype.header.fColor = val
        this.updateAttr() 
      }
    },
    
    ChtTypeHeaderFillOpacity: {
      get() { return ( this.chartItem.Chttype && this.chartItem.Chttype.header.fOpacity ) },
      set(val) { 
        this.localChttype.header.fOpacity = parseFloat(val || 0)
        this.updateAttr() 
      }
    },
    
    ChtTypeHeaderMargin: {
      get() { return ( this.chartItem.Chttype && this.chartItem.Chttype.header.margin ) },
      set(val) { 
        this.localChttype.header.margin = parseFloat(val || 0)
        this.updateAttr() 
      }
    },
    
    ChtTypeHeaderDevide: {
      get() { return ( this.chartItem.Chttype && this.chartItem.Chttype.header.devide ) },
      set(val) { 
        this.localChttype.header.devide = val
        this.updateAttr() 
      }
    },
    
    ChtTypeHeaderLineVisible: {
      get() { return ( this.chartItem.Chttype && this.chartItem.Chttype.header.line.visible )  },
      set(val) { 
        this.localChttype.header.line.visible = val 
        this.updateAttr() 
      }
    },
    
    ChtTypeHeaderLineColor: {
      get() { return ( this.chartItem.Chttype && this.chartItem.Chttype.header.line.color )  },
      set(val) { 
        this.localChttype.header.line.color = val
        this.updateAttr() 
      }
    },
    
    ChtTypeHeaderLineOpacity: {
      get() { return ( this.chartItem.Chttype && this.chartItem.Chttype.header.line.opacity )  },
      set(val) { 
        this.localChttype.header.line.opacity = parseFloat(val || 0)
        this.updateAttr() 
      }
    },
    
    ChtTypeHeaderLineWidth: {
      get() { return ( this.chartItem.Chttype && this.chartItem.Chttype.header.line.width )  },
      set(val) { 
        this.localChttype.header.line.width = parseFloat(val || 0)
        this.updateAttr() 
      }
    },
    
    ChtTypeCellsTextSize: {
      get() { return ( this.chartItem.Chttype && this.chartItem.Chttype.cells.tSize ) },
      set(val) { 
        this.localChttype.cells.tSize = parseFloat(val || 0)
        this.updateAttr() 
      }
    },
    
    ChtTypeCellsHeight: {
      get() { return ( this.chartItem.Chttype && this.chartItem.Chttype.cells.height ) },
      set(val) { 
        this.localChttype.cells.height = parseFloat(val || 0)
        this.updateAttr() 
      }
    },
    
    ChtTypeCellsPadding: {
      get() { return ( this.chartItem.Chttype && this.chartItem.Chttype.cells.cellSpace ) },
      set(val) { 
        this.localChttype.cells.cellSpace = parseFloat(val || 0)
        this.updateAttr() 
      }
    },
    
    ChtTypeCellsTotal: {
      get() { return ( this.chartItem.Chttype && this.chartItem.Chttype.cells.total ) },
      set(val) { 
        this.localChttype.cells.total = val
        this.updateAttr() 
      }
    },
    
    ChtTypeCellsLineVisible: {
      get() { return ( this.chartItem.Chttype && this.chartItem.Chttype.cells.line.visible ) },
      set(val) { 
        this.localChttype.cells.line.visible = val
        this.updateAttr() 
      }
    },
    
    ChtTypeCellsLineColor: {
      get() { return ( this.chartItem.Chttype && this.chartItem.Chttype.cells.line.color ) },
      set(val) { 
        this.localChttype.cells.line.color = val
        this.updateAttr() 
      }
    },
    
    ChtTypeCellsLineOpacity: {
      get() { return ( this.chartItem.Chttype && this.chartItem.Chttype.cells.line.opacity ) },
      set(val) { 
        this.localChttype.cells.line.opacity = parseFloat(val || 0)
        this.updateAttr() 
      }
    },
    
    ChtTypeCellsLineWidth: {
      get() { return ( this.chartItem.Chttype && this.chartItem.Chttype.cells.line.width ) },
      set(val) { 
        this.localChttype.cells.line.width = parseFloat(val || 0)
        this.updateAttr() 
      }
    },
    
    ChtTypeCellsBarHeight: {
      get() { return ( this.chartItem.Chttype && this.chartItem.Chttype.cells.bar.height ) },
      set(val) { 
        this.localChttype.cells.bar.height = parseFloat(val || 0)
        this.updateAttr() 
      }
    },
    
    ChtTypeCellsBarColor: {
      get() { return ( this.chartItem.Chttype && this.chartItem.Chttype.cells.bar.color ) },
      set(val) { 
        this.localChttype.cells.bar.color = val
        this.updateAttr() 
      }
    },
    
    ChtTypeCellsBarOpacity: {
      get() { return ( this.chartItem.Chttype && this.chartItem.Chttype.cells.bar.opacity ) },
      set(val) { 
        this.localChttype.cells.bar.opacity = parseFloat(val || 0)
        this.updateAttr() 
      }
    },
    
    // ChtTypeHeaderDepth: {
    //   get() { return ( this.chartItem.Chttype && this.chartItem.Chttype.header.font ) },
    //   set(val) { 
    //     this.localChttype.header.depth = parseFloat(val || 0) 
    //     this.updateAttr() 
    //   }
    // },

  },
  watch: {
    'chartItem.Chttype': {
      handler(val) {
        if (!val) return
        // console.log('Chttype', val)
        this.localChttype = JSON.parse(JSON.stringify(val))
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),

    appendAttr(name) {
      // console.log('appendAttr', name)
      // console.log('appendAttr', this.default,this.default[name])
      this.localChttype.header[name].push(this.default[name][0])
      this.updateAttr()
    },
    clickRemoveBtn(i, j, el) {
      // console.log('clickRemoveBtn', i, j, el)
      this.yes = () => {
        this.msgOpen = false
        this.removeAttr(i, j, el)
        this.yes = () => {}
      }
      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Remove Style"
      this.msgInfo.message = "Do you want to delete this style?"
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true
    },
    removeAttr(index, name) {
      // console.log('removeAttr', index, name)
      // console.log('removeAttr', this.localColumns,this.localColumns[name])
      this.localChttype.header[name][index] = null
      this.localChttype.header[name] = this.localChttype.header[name].filter(attr => !!attr)
      this.updateAttr()
    },
    updateAttr() {
      this.setChartItem({ Chttype: JSON.parse(JSON.stringify(this.localChttype)) })
    },
  },
}
</script>
