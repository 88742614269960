import JChartTabNavigation from '@/components/chart/tabs/JChartTabNavigation'
import JChartTabDatabase from '@/components/chart/tabs/JChartTabDatabase'
import JChartTabCanvas from '@/components/chart/tabs/JChartTabCanvas'
import JChartTabText from '@/components/chart/tabs/JChartTabText'
import JChartTabComm from './JChartTabComm'
import JChartTabType from './JChartTabType'
import JChartTabCol from './JChartTabCol'

export default {
  JChartTabNavigation,
  JChartTabDatabase,
  JChartTabCanvas,
  JChartTabText,
  JChartTabComm,
  JChartTabType,
  JChartTabCol,
}
