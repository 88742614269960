<template>
  <v-flex class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Tabs</span>
    </div>
    <div class="chart_properties_contents">
      <j-alert
        v-model="msgOpen"
        :title="msgInfo.title"
        :type="msgInfo.type"
        :message="msgInfo.message"
        :button="msgInfo.button"
        :button-text="msgInfo.buttonText"
        @yes="yes()"
        @cancel="msgOpen = false"
      />
      <div class="header">
        Tabs
        <small class="type_ref03">(Name)</small>
        <a class="close" v-on:click="collapsed['tab'] = !collapsed['tab']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['tab']">
        <div class="type_common_input">
          <label class="type_gray second_depth">
            Coordinates
            <small class="type_ref03">(x,y)</small>
          </label>
          <input v-model="TapsX" type="number" class="type_mgl0 type_double" />
          <input v-model="TapsY" type="number" />
        </div>
        <div class="type_common_input">
          <label class="type_gray second_depth">Space</label>
          <input v-model="MenuSpace" type="number" />
        </div>
        <div class="type_common_input">
          <span class="label">Display Tab-Menu</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="DisplayTabMenu" type="radio" name="DisplayTabMenu" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        <span 
          data-grid-quickbtn="btn" 
          class="label jcon_temp_plus type_header btn_after_little" 
          style="font-family: roboto !important; color: #83d2f5;" 
          @click="appendAttr('menu')"
        >Menus</span>
        <a class="close" v-on:click="collapsed['menu'] = !collapsed['menu']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['menu']">
        <div v-for="(attr, i) in localMenuAttrs" class="content_list" :key="attr.index">
          <div class="header">
            <span data-grid-quickbtn="btn" class="label jcon_temp_minus type_header_before" @click="clickRemoveBtn(i, 'menu')">No.{{ i + 1 }}</span>
          </div>
          <div>
            <div class="type_inner">
              <span class="label" style="padding-top: 0.5rem; color: #bdbcbc">Title (Main/Sub)</span>
              <div class="type_common_input">
                <input class="text_input type_100" v-model="attr.mainTitle" type="text" @input="(e) => updateAttr(e.target.value, i, 'mainTitle', 'menu')" />
              </div>
              <div class="type_common_input" style="margin-bottom: .7rem;">
                <input class="text_input type_100" v-model="attr.subTitle" type="text" @input="(e) => updateAttr(e.target.value, i, 'subTitle', 'menu')" />
              </div>
              <div>
                <label class="label">Action</label>
                <div class="type_inner">
                  <div class="type_common_select">
                    <label class="type_gray third_depth">Target</label>
                    <select v-model="attr.action.target" style="width: 12rem;" @input="(e) => { updateAttr(e.target.value, i, 'action', 'menu', 'target') }">
                      <option v-for="option in targetOptions" :key="option.index" :value="option.value">{{option.text}}</option>
                    </select>
                  </div>
                  <div class="type_common_select">
                    <label class="type_gray third_depth">Type</label>
                    <select v-model="attr.action.component" style="width: 12rem;" @input="(e) => { onComponentChanged(e.target.value, i) }">
                      <option v-for="option in targetCompOptions" :key="option.index" :value="option.value">{{option.text}}</option>
                    </select>
                  </div>
                  <div class="type_common_select">
                    <label class="type_gray third_depth">Name</label>
                    <select v-model="attr.action.id" style="width: 12rem;" @input="(e) => { onComponentItemChanged(e.target.value, i) }">
                      <option v-for="option in componentItems(i)" :key="option.index" :value="option.id">{{option.name}}</option>
                    </select>
                  </div>
                  <div class="type_common_input">
                    <label class="type_gray third_depth">Filter String</label>
                    <input v-model="attr.action.filterString" type="text" style="width: 12rem; text-align: left;" @input="updateAttr()" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        <span data-grid-quickbtn="btn" class="label jcon_temp_plus type_header btn_after" style="font-family: roboto !important; color: #83d2f5;" @click="appendAttr('style')">Style</span>
        <a class="close" v-on:click="collapsed['style'] = !collapsed['style']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['style']">
        <div v-for="(attr, i) in localStyleAttrs" class="content_list" :key="attr.index">
          <div class="header">
            <span data-grid-quickbtn="btn" class="label jcon_temp_minus type_header_before" @click="clickRemoveBtn(i, 'style')">Main Title</span>
          </div>
            <div class="type_inner">
              <div class="type_common_input">
                <label class="type_gray second_depth">
                  Coordinates
                  <small class="type_ref03">(x,y)</small>
                </label>
                <input v-model="attr.x" type="number" class="type_mgl0 type_double"  @input="(e) => { updateAttr(e.target.value, i, 'x', 'style') }"/>
                <input v-model="attr.y" type="number"  @input="(e) => { updateAttr(e.target.value, i, 'y') }"/>
              </div>
              <div class="type_common_select">
                <label class="type_gray second_depth">Font</label>
                <select v-model="attr.font" dir="rtl" @input="(e) => { updateAttr(e.target.value, i, 'font', 'style') }">
                  <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
                </select>
              </div>
              <div class="type_common_select">
                <label class="type_gray second_depth">Style / Size</label>
                <div class="row">
                  <select v-model="attr.class" class="type70" dir="rtl" @input="(e) => { updateAttr(e.target.value, i, 'class', 'style') }">
                    <option v-for="option in fontStyleOptions" :key="option.index" :value="option.value">{{option.text}}</option>
                  </select>
                  <input v-model="attr.size" class="type_middle_left" type="number" @input="(e) => updateAttr(e.target.value, i, 'size', 'style')" />
                </div>
              </div>
              <span class="label">Hovor</span>
              <div class="type_inner">
                <div class="type_common_input">
                  <label class="type_gray second_depth">Mouse Out</label>
                  <div class="row">
                    <j-color-picker v-model="attr.outColor" @input="updateAttr"/>
                  </div>
                </div>
                <div class="type_common_input">
                  <label class="type_gray label second_depth">Mouse Over</label>
                  <div class="row">
                    <j-color-picker v-model="attr.overColor" @input="updateAttr"/>
                  </div>
                </div>
                <div class="type_common_input">
                  <label class="type_gray label second_depth">Selected</label>
                  <div class="row">
                    <j-color-picker v-model="attr.selectedColor" @input="updateAttr"/>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Bullet
        <a class="close" v-on:click="collapsed['bullet'] = !collapsed['bullet']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['bullet']">
        <span class="label">Style</span>
        <div class="type_inner">
          <div class="type_common_input">
            <label class="type_gray second_depth">
              Width / Height
            </label>
            <input v-model="width" type="number" class="type_mgl0 type_double"/>
            <input v-model="height" type="number"/>
          </div>
          <div class="type_common_input">
            <label class="type_gray second_depth">
              Radius
            </label>
            <input v-model="radius" type="number" class="type_mgl0 type_double" />
          </div>
          <div class="type_common_input">
            <label class="type_gray second_depth">
              Stroke / Color
            </label>
            <div class="row">
              <input v-model="stroke" type="number" class="type_mgl0 type_double" />
              <j-color-picker v-model="strokeColor"/>
            </div>
          </div>
          </div>
          <span class="label">Hovor</span>
          <div class="type_inner">
            <div class="type_common_input">
              <label class="type_gray second_depth">Mouse Out</label>
              <div class="row">
                <j-color-picker v-model="outColor" :gradation="true" @input="updateAttr"/>
              </div>
            </div>
            <div class="type_common_input">
              <label class="type_gray label second_depth">Mouse Over</label>
              <div class="row">
                <j-color-picker v-model="overColor" :gradation="true" @input="updateAttr"/>
              </div>
            </div>
            <div class="type_common_input">
              <label class="type_gray label second_depth">Selected</label>
              <div class="row">
                <j-color-picker v-model="selectedColor" :gradation="true" @input="updateAttr"/>
              </div>
            </div>
          </div>
      </v-flex>
    </div>
    <div style="height: 10rem;" ></div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import _ChartDataDefault from '@/primitives/_pageSvglibTabDefault'
import { mapState, mapMutations } from "vuex"
import { QueryValidationMixin } from "@/mixins/sqlValidator"
import { SystemCodeService } from "@/services"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-svglib-tab-tabs',
  mixins: [
    QueryValidationMixin,
    StaticOptions
  ],
  data: () => ({
    // Service Instances -------------
    systemCodeService: null,

    collapsed: {
      tab: true,
      menu: true,
      style: true,
      bullet: true,
    },
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Delete", "No", "Cancel"]
    },
    localTabAttrs: {},
    localTabs: {},
    localMenuAttrs: [],
    localStyleAttrs: [],
    localBulletAttrs: {},
    tabActivator: null,
    exp: {
      H: false,
      F: false,
    },
    validated: {
      TableFootSQL: { valid: true, message: ' '},
      TableFootSQL_0: { valid: true, message: ' '},
      TableFootSQL_1: { valid: true, message: ' '},
      TableFootSQL_2: { valid: true, message: ' '},
      TableFootSQL_3: { valid: true, message: ' '},
    },
    targetCompItems: [],
    
    msgOpen: false,
    yes: null
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),

    headerAttrs() {
      return Array.from({ length: this.localMenuAttrs.length  }, (_, i) => i) 
    },

    TapsX: {
      get() { return this.localTabs.TapsX },
      set(val) { this.localTabs.TapsX = val; this.updateAttr() },
    },

    TapsY: {
      get() { return this.localTabs.TapsY || 0 },
      set(val) { this.localTabs.TapsY = val; this.updateAttr() },
    },

    MenuSpace: {
      get() {return this.localTabs.MenuSpace || 0 },
      set(val) { this.localTabs.MenuSpace = parseFloat(val); this.updateAttr() },
    },
    DisplayTabMenu: {
      get() {return this.localTabs.DisplayTabMenu || 'Y' },
      set(val) { this.localTabs.DisplayTabMenu = val; this.updateAttr() },
    },
    InitialTarget: {
      get() {return this.localTabs.InitialTarget },
      set(val) { this.localTabs.InitialTarget = val; this.updateAttr() },
    },

    width: {
      get() { return this.localBulletAttrs.width || 0 },
      set(val) { this.localBulletAttrs.width = parseFloat(val); this.updateAttr() }
    },
    
    height: {
      get() { return this.localBulletAttrs.height || 0 },
      set(val) { this.localBulletAttrs.height = parseFloat(val); this.updateAttr() }
    },
    
    radius: {
      get() { return this.localBulletAttrs.radius || 0 },
      set(val) { this.localBulletAttrs.radius = parseFloat(val); this.updateAttr() }
    },
    
    stroke: {
      get() { return this.localBulletAttrs.stroke || 0 },
      set(val) { this.localBulletAttrs.stroke = parseFloat(val); this.updateAttr() }
    },
    
    strokeColor: {
      get() { return this.localBulletAttrs.strokeColor || 0 },
      set(val) { this.localBulletAttrs.strokeColor = val; this.updateAttr() }
    },
    
    outColor: {
      get() { return this.localBulletAttrs.outColor || 0 },
      set(val) { this.localBulletAttrs.outColor = val; this.updateAttr() }
    },
    
    overColor: {
      get() { return this.localBulletAttrs.overColor || 0 },
      set(val) { this.localBulletAttrs.overColor = val; this.updateAttr() }
    },
    
    selectedColor: {
      get() { return this.localBulletAttrs.selectedColor || 0 },
      set(val) { this.localBulletAttrs.selectedColor = val; this.updateAttr() }
    },

    componentItems() {
    return (index) => {
      return this.targetCompItems[index] || []
    }
  }
  },
  watch: {
    'chartItem.TabAttrs': {
      handler(val) {
        if (!val) return
        this.localTabAttrs = JSON.parse(JSON.stringify(val))
        this.localTabs= this.localTabAttrs.tabs
        this.localMenuAttrs = this.localTabAttrs.tabs.Menus
        this.localStyleAttrs = this.localTabAttrs.tabs.Style
        this.localBulletAttrs = this.localTabAttrs.tabs.Bullet
      },
      deep: true
    },
    localMenuAttrs: {
      handler(val) {
        if (!val || this.targetCompItems.length > 0) return
        this.setComponentOptions()
      },
      deep: true
    },
  },
  created() {
    this.systemCodeService = new SystemCodeService()
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),

    async onComponentChanged(val, i) {

      // init previous selected value
      this.localMenuAttrs[i].action.id = ''
  
      // init index targetCompItems
      this.$set(this.targetCompItems, i, [])

      if(val == 'ServiceCompPage') {
        this.$set(this.targetCompItems, i, this.targetCompPageOptions)
      } else {
        try {
          const res = await this.getComponentOptions(val)
          this.$set(this.targetCompItems, i, res)
        } catch (error) {
          this.$set(this.targetCompItems, i, [])
        }
      }

      // updateAttr AFTER DOM UPDATE
      this.$nextTick(() => {
        this.updateAttr(val, i, 'action', 'menu', 'component')
      })
    },
    onComponentItemChanged(val, i) {
      let item__ = this.targetCompItems[i].find(item_ => item_.id == val)

      if(!item__) return

      this.localMenuAttrs[i]['action']['id'] = item__.id
      this.localMenuAttrs[i]['action']['no'] = item__.code
      this.updateAttr()
    },
    onSave() {
      this.$emit('save')
    },
    onValidate(typeName) {
      // Fix Me type name
      if(typeName == 'headerJson') {
        this.validated.headerJson = this.validateJson(this.headerJson)
        if(this.validated.headerJson.valid) this.headerJson = this.validated.headerJson.json
      } else {
        this.validateSql(this[typeName]).then(res => {
          this.validated[typeName] = res
        })
      }
    },
    
    // move to computed 
    // componentItems(i) {
    //   return this.targetCompItems[i] || []
    // },
    editorOpen(i) {
      let opened_ = JSON.parse(JSON.stringify(this.editorOpended))
      opened_[i] = !opened_[i]

      // // For updating the changes on validating, because of auto closing
      // // after updateAttr.
      if(!opened_[i]) this.updateAttr()
      
      this.editorOpended = opened_

      return this.editorOpended[i]
    },
    getComponentOptions(name) {
      return new Promise(resolve => {
        if(name == 'ServiceDashboard') {
          this.systemCodeService.dashboardOptions(__C.PAGE_COMPONENT.TYPE_MODAL, (res) => {
            resolve(res)
          })
        } else if(name == 'ServiceDatatable') {
          this.systemCodeService.datatableOptions(__C.PAGE_COMPONENT.TYPE_MODAL, (res) => {
            resolve(res)
          })
        } else if(name == 'ServicePageSvg') {
          this.systemCodeService.chartOptions(__C.CHART.CAT_CODE_COMPLIB_SVG_PAGE, 'ALL', (res) => {
            resolve(res)
          })
        } else {
          this.systemCodeService.chartOptions(__C.CHART.CAT_CODE_COMPLIB_SVG_TAB, 'ALL', (res) => {
            resolve(res)
          })
        }
      })
    },
    setComponentOptions() {
      let self_ = this
      self_.targetCompItems = []
      async function set_(name) {
        self_.targetCompItems.push(await self_.getComponentOptions(name))
      }
      
      if(this.localMenuAttrs && this.localMenuAttrs.length > 0)
        this.localMenuAttrs.forEach(attr => { 
          if(attr.action && attr.action.component) set_(attr.action.component)
        })
    },

    // ### Object Process ### -----------------------------------
    appendAttr(type) {
      if(type == 'menu') {
        this.localMenuAttrs.push(_ChartDataDefault.ModalTaps.Menus[0])
        this.targetCompItems.push([])
      } else if(type == 'style') {
        this.localStyleAttrs.push(_ChartDataDefault.ModalTaps.Style[0])
      }
      this.updateAttr()
    },
    clickRemoveBtn(i, j, el) {
      this.yes = () => {
        this.msgOpen = false
        this.removeAttr(i, j, el)
        this.yes = () => {}
      }
      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Remove Style"
      this.msgInfo.message = "Do you want to delete this style?"
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true
    },
    removeAttr(index, type) {
      if(type == 'menu') {
        this.localMenuAttrs[index] = null
        this.localMenuAttrs = this.localMenuAttrs.filter(attr => !!attr)

        this.targetCompItems[index] = null
        this.targetCompItems = this.targetCompItems.filter(attr => !!attr)

      } else if (type == 'style') {
        this.localStyleAttrs[index] = null
        this.localStyleAttrs = this.localStyleAttrs.filter(attr => !!attr)
      }
      
      this.updateAttr()
    },
    updateAttr(val, i, el, type, elSub) {
      if(elSub) {
        if(type == 'menu') this.localMenuAttrs[i][el][elSub] = val
        else if (type == 'style') this.localStyleAttrs[i][el][elSub] = val
      } else if(el) {
        if(type == 'menu') this.localMenuAttrs[i][el] = val
        else if (type == 'style') this.localStyleAttrs[i][el] = val
      }
      
      this.localTabAttrs.tabs = null

      this.setChartItem({
        TabAttrs: {
          ...JSON.parse(JSON.stringify(this.localTabAttrs)), 
          tabs: {
            ...JSON.parse(JSON.stringify(this.localTabs)),
            Menus: [...JSON.parse(JSON.stringify(this.localMenuAttrs))],
            Style: [...JSON.parse(JSON.stringify(this.localStyleAttrs))],
            Bullet: JSON.parse(JSON.stringify(this.localBulletAttrs)),
          }
        }
      })
    }
  }
}
</script>
